:root {
  --s-textcolor: #000000;
  --s-textcolor-02: #2F1657;
  --s-textcolor-03: #D6294A;
  --s-sitecolor: #2F1657;
  --s-sitecolor-02: #DFFE52;
  --s-sitecolor-03: #D6294A;
  --s-linkcolor: #000000;
  --s-linkcolor-hov: #2F1657;
  --s-btn-bgcolor: transparent;
  --s-btn-color: #D6294A;
  --s-btn-border: #D6294A;
  --s-btn-border-hov: #D6294A;
  --s-btn-bgcolor-hov: #D6294A;
  --s-btn-color-hov: #FFFFFF;
  --s-btn-secondary-color: #D6294A;
  --s-pdf-color: #000000;
  --s-pdf-bgcolor-hov: #000000;
  --s-pdf-color-hov: #FFFFFF;
  --s-pdf-border: #000000;
  --s-slider-control-color: #2F1657;
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  color: var(--s-btn-color);
  font-size: 14px;
  line-height: 1.28571429;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  cursor: pointer;
  padding: 30px 80px 30px 20px;
  appearance: none;
  text-decoration: none;
  display: inline-block;
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
  font-weight: 500;
  width: 350px;
  max-width: 100%;
  text-align: left;
  box-sizing: border-box;
  border: 1px solid var(--s-btn-border);
  position: relative;
  background-color: var(--s-btn-bgcolor);
  background-size: 0 100%;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, var(--s-btn-bgcolor-hov), var(--s-btn-bgcolor-hov));
}
.button:before {
  content: '';
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 11px;
  height: 20px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/icon-arrow-right-black.svg);
  background-color: var(--s-btn-color);
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
}
.button:after {
  content: '';
  position: absolute;
  right: 22px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 2px;
  background-color: var(--s-btn-color);
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
  border-radius: 2000px;
}
@media (hover: hover) and (pointer: fine) {
  .button:hover,
  .button:focus {
    border-color: var(--s-btn-border-hov);
    color: var(--s-btn-color-hov);
    background-size: 100% 100%;
  }
  .button:hover:before,
  .button:focus:before {
    background-color: var(--s-btn-color-hov);
  }
  .button:hover:after,
  .button:focus:after {
    width: 41px;
    background-color: var(--s-btn-color-hov);
  }
}
.button-pdf {
  color: var(--s-btn-color);
  font-size: 14px;
  line-height: 1.28571429;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  cursor: pointer;
  padding: 30px 80px 30px 20px;
  appearance: none;
  text-decoration: none;
  display: inline-block;
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
  font-weight: 500;
  width: 350px;
  max-width: 100%;
  text-align: left;
  box-sizing: border-box;
  border: 1px solid var(--s-btn-border);
  position: relative;
  background-color: var(--s-btn-bgcolor);
  background-size: 0 100%;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, var(--s-btn-bgcolor-hov), var(--s-btn-bgcolor-hov));
}
.button-pdf:before {
  content: '';
  position: absolute;
  right: 20px;
  top: 50%;
  width: 22px;
  height: 30px;
  transform: translateY(-50%);
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/icon-pdf-black.svg);
  background-color: var(--s-btn-color);
  transition: all 0.4s cubic-bezier(0.87, 0, 0.13, 1);
}
@media (hover: hover) and (pointer: fine) {
  .button-pdf:hover,
  .button-pdf:focus {
    color: var(--s-pdf-color-hov);
    background-size: 100% 100%;
  }
  .button-pdf:hover:before,
  .button-pdf:focus:before {
    background-color: var(--s-pdf-color-hov);
  }
}
@media (max-width: 1023px) {
  body:not(.layout1) #head {
    padding: 0 20.86864407%;
    box-sizing: border-box;
  }
}
.footarea {
  width: calc(100% + 20px);
  margin-left: -10px;
  display: flex;
  flex-wrap: wrap;
}
.footpart {
  width: calc(50% - 20px);
  margin-left: 10px;
  margin-right: 10px;
}
.footpart--one,
.footpart--four,
.footpart--five {
  width: calc(100% - 20px);
}
.area--one {
  width: 102.11864407%;
  margin-left: -1.05932203%;
}
.area--one .unit {
  width: 97.9253112%;
  margin-left: 1.0373444%;
  margin-right: 1.0373444%;
}
.area--one .unitOne--1-2 {
  width: 47.9253112%;
}
.area--one .unitOne--1-3 {
  width: 47.9253112%;
}
.area--one .unitOne--1-3 .unit__content {
  height: 100%;
}
.area--one .unitOne--1-3 .unit__body {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.area--one .unitOne--1-3 .unit__body .part--styleTwo {
  margin-top: auto;
  padding-top: calc(var(--spacePart) * 1);
}
.area--one .unitOne--1-4 {
  width: 47.9253112%;
}
.area--one .unitOne--1-1 .unit__body {
  width: 102.11864407%;
  margin-left: -1.0373444%;
  display: flex;
  flex-wrap: wrap;
}
.area--one .unitOne--1-1 .part {
  width: 97.9253112%;
  margin-left: 1.0373444%;
  margin-right: 1.0373444%;
}
.area--one .unitOne--1-1 .part.part--slim {
  width: 47.9253112%;
}
@media (max-width: 1023px) {
  .area--one .unit--form,
  .area--one .unitTwo,
  .area--one .unitThree {
    padding: 0 20.43568465%;
    box-sizing: border-box;
  }
}
.area--one .unitFour {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-areas: "left right";
  align-items: center;
  gap: 40px;
}
.area--one .unitFour + .unitFour {
  margin-top: calc(var(--spaceTotal) * 2 + var(--spaceUnit) * 1);
}
.area--one .unitFour--imageLeft .unit__background {
  grid-area: left;
}
.area--one .unitFour--imageLeft .unit__content {
  grid-area: right;
}
.area--one .unitFour--imageRight .unit__background {
  grid-area: right;
}
.area--one .unitFour--imageRight .unit__content {
  grid-area: left;
}
.area--one .unit--slider:first-child {
  margin-top: calc((var(--spaceTotal) * -3) + (var(--spacePart) * 2));
}
.area--one .unit--slider:last-child {
  margin-bottom: calc((var(--spaceTotal) * -3) + (var(--spacePart) * 2));
}
/*# sourceMappingURL=./screen-medium.css.map */